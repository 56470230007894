import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0310664c = () => interopDefault(import('../sites/soh/pages/user/activate.vue' /* webpackChunkName: "sites/soh/pages/user/activate" */))
const _225cc268 = () => interopDefault(import('../sites/soh/pages/articleList/index.vue' /* webpackChunkName: "sites/soh/pages/articleList/index" */))
const _45591032 = () => interopDefault(import('../sites/soh/pages/index.vue' /* webpackChunkName: "sites/soh/pages/index" */))
const _001d62b4 = () => interopDefault(import('../sites/soh/pages/search/index.vue' /* webpackChunkName: "sites/soh/pages/search/index" */))
const _96762890 = () => interopDefault(import('../sites/soh/pages/contact/index.vue' /* webpackChunkName: "sites/soh/pages/contact/index" */))
const _73165c04 = () => interopDefault(import('../sites/soh/pages/checkout/index.vue' /* webpackChunkName: "sites/soh/pages/checkout/index" */))
const _b209ca04 = () => interopDefault(import('../sites/soh/pages/login.vue' /* webpackChunkName: "sites/soh/pages/login" */))
const _7dbb351e = () => interopDefault(import('../sites/soh/pages/register.vue' /* webpackChunkName: "sites/soh/pages/register" */))
const _87e786ee = () => interopDefault(import('../sites/soh/pages/passwordReset.vue' /* webpackChunkName: "sites/soh/pages/passwordReset" */))
const _aefd4868 = () => interopDefault(import('../sites/soh/pages/sitemapconfig.vue' /* webpackChunkName: "sites/soh/pages/sitemapconfig" */))
const _37053030 = () => interopDefault(import('../sites/soh/pages/maintenance.vue' /* webpackChunkName: "sites/soh/pages/maintenance" */))
const _92373c6e = () => interopDefault(import('../sites/soh/pages/user/index.vue' /* webpackChunkName: "sites/soh/pages/user/index" */))
const _c96a7258 = () => interopDefault(import('../sites/soh/pages/passwordForgotten.vue' /* webpackChunkName: "sites/soh/pages/passwordForgotten" */))
const _22738928 = () => interopDefault(import('../sites/soh/pages/checkout/info.vue' /* webpackChunkName: "sites/soh/pages/checkout/info" */))
const _ebc7dba0 = () => interopDefault(import('../sites/soh/pages/checkout/finished.vue' /* webpackChunkName: "sites/soh/pages/checkout/finished" */))
const _80c9df38 = () => interopDefault(import('../sites/soh/pages/checkout/methods.vue' /* webpackChunkName: "sites/soh/pages/checkout/methods" */))
const _421aad1e = () => interopDefault(import('../sites/soh/pages/checkout/importOrder/index.vue' /* webpackChunkName: "sites/soh/pages/checkout/importOrder/index" */))
const _54a269f2 = () => interopDefault(import('../sites/soh/pages/user/shoppingLists/index.vue' /* webpackChunkName: "sites/soh/pages/user/shoppingLists/index" */))
const _570d3781 = () => interopDefault(import('../sites/soh/pages/user/orders/index.vue' /* webpackChunkName: "sites/soh/pages/user/orders/index" */))
const _6a20357c = () => interopDefault(import('../sites/soh/pages/user/settings.vue' /* webpackChunkName: "sites/soh/pages/user/settings" */))
const _5246a9ea = () => interopDefault(import('../sites/soh/pages/user/dashboard.vue' /* webpackChunkName: "sites/soh/pages/user/dashboard" */))
const _e63ea72e = () => interopDefault(import('../sites/soh/pages/user/addressList.vue' /* webpackChunkName: "sites/soh/pages/user/addressList" */))
const _6c1337f4 = () => interopDefault(import('../sites/soh/pages/user/password.vue' /* webpackChunkName: "sites/soh/pages/user/password" */))
const _d5487828 = () => interopDefault(import('../sites/soh/pages/checkout/payment/callback.vue' /* webpackChunkName: "sites/soh/pages/checkout/payment/callback" */))
const _7055b3ae = () => interopDefault(import('../sites/soh/pages/user/orders/_id.vue' /* webpackChunkName: "sites/soh/pages/user/orders/_id" */))
const _ea9c39a2 = () => interopDefault(import('../sites/soh/pages/user/shoppingLists/_id.vue' /* webpackChunkName: "sites/soh/pages/user/shoppingLists/_id" */))
const _f4294074 = () => interopDefault(import('../sites/soh/pages/article/_slug.vue' /* webpackChunkName: "sites/soh/pages/article/_slug" */))
const _25ca04f8 = () => interopDefault(import('../sites/soh/pages/articleList/_slug.vue' /* webpackChunkName: "sites/soh/pages/articleList/_slug" */))
const _06689245 = () => interopDefault(import('../sites/soh/pages/page/_slug.vue' /* webpackChunkName: "sites/soh/pages/page/_slug" */))
const _4e9f7c7f = () => interopDefault(import('../sites/soh/pages/product/_slug.vue' /* webpackChunkName: "sites/soh/pages/product/_slug" */))
const _489ff118 = () => interopDefault(import('../sites/soh/pages/_.vue' /* webpackChunkName: "sites/soh/pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktivovat",
    component: _0310664c,
    name: "user-activate___cs"
  }, {
    path: "/clanky",
    component: _225cc268,
    name: "articleList___cs"
  }, {
    path: "/en",
    component: _45591032,
    name: "index___en"
  }, {
    path: "/hledat",
    component: _001d62b4,
    name: "search___cs"
  }, {
    path: "/kontakt",
    component: _96762890,
    name: "contact___cs"
  }, {
    path: "/kosik",
    component: _73165c04,
    name: "checkout___cs"
  }, {
    path: "/prihlasit",
    component: _b209ca04,
    name: "login___cs"
  }, {
    path: "/registrovat",
    component: _7dbb351e,
    name: "register___cs"
  }, {
    path: "/resetovat-heslo",
    component: _87e786ee,
    name: "passwordReset___cs"
  }, {
    path: "/sitemapconfig",
    component: _aefd4868,
    name: "sitemapconfig___cs"
  }, {
    path: "/udrzba",
    component: _37053030,
    name: "maintenance___cs"
  }, {
    path: "/uzivatel",
    component: _92373c6e,
    name: "user___cs"
  }, {
    path: "/zapomenute-heslo",
    component: _c96a7258,
    name: "passwordForgotten___cs"
  }, {
    path: "/en/activate",
    component: _0310664c,
    name: "user-activate___en"
  }, {
    path: "/en/articles",
    component: _225cc268,
    name: "articleList___en"
  }, {
    path: "/en/cart",
    component: _73165c04,
    name: "checkout___en"
  }, {
    path: "/en/contact",
    component: _96762890,
    name: "contact___en"
  }, {
    path: "/en/forgotten-password",
    component: _c96a7258,
    name: "passwordForgotten___en"
  }, {
    path: "/en/login",
    component: _b209ca04,
    name: "login___en"
  }, {
    path: "/en/maintenance",
    component: _37053030,
    name: "maintenance___en"
  }, {
    path: "/en/register",
    component: _7dbb351e,
    name: "register___en"
  }, {
    path: "/en/reset-password",
    component: _87e786ee,
    name: "passwordReset___en"
  }, {
    path: "/en/search",
    component: _001d62b4,
    name: "search___en"
  }, {
    path: "/en/sitemapconfig",
    component: _aefd4868,
    name: "sitemapconfig___en"
  }, {
    path: "/en/user",
    component: _92373c6e,
    name: "user___en"
  }, {
    path: "/kosik/dodaci-udaje",
    component: _22738928,
    name: "checkout-info___cs"
  }, {
    path: "/kosik/dokonceni",
    component: _ebc7dba0,
    name: "checkout-finished___cs"
  }, {
    path: "/kosik/doprava-a-platba",
    component: _80c9df38,
    name: "checkout-methods___cs"
  }, {
    path: "/kosik/import-objednavky",
    component: _421aad1e,
    name: "checkout-importOrder___cs"
  }, {
    path: "/uzivatel/nakupni-seznamy",
    component: _54a269f2,
    name: "user-shoppingLists___cs"
  }, {
    path: "/uzivatel/objednavky",
    component: _570d3781,
    name: "user-orders___cs"
  }, {
    path: "/uzivatel/osobni-udaje",
    component: _6a20357c,
    name: "user-settings___cs"
  }, {
    path: "/uzivatel/prehled",
    component: _5246a9ea,
    name: "user-dashboard___cs"
  }, {
    path: "/uzivatel/seznam-adres",
    component: _e63ea72e,
    name: "user-addressList___cs"
  }, {
    path: "/uzivatel/zmena-hesla",
    component: _6c1337f4,
    name: "user-password___cs"
  }, {
    path: "/checkout/payment/callback",
    component: _d5487828,
    name: "checkout-payment-callback___cs"
  }, {
    path: "/en/cart/delivery-details",
    component: _22738928,
    name: "checkout-info___en"
  }, {
    path: "/en/cart/finish",
    component: _ebc7dba0,
    name: "checkout-finished___en"
  }, {
    path: "/en/cart/import-order",
    component: _421aad1e,
    name: "checkout-importOrder___en"
  }, {
    path: "/en/cart/shipping-and-payment",
    component: _80c9df38,
    name: "checkout-methods___en"
  }, {
    path: "/en/user/address-list",
    component: _e63ea72e,
    name: "user-addressList___en"
  }, {
    path: "/en/user/change-password",
    component: _6c1337f4,
    name: "user-password___en"
  }, {
    path: "/en/user/orders",
    component: _570d3781,
    name: "user-orders___en"
  }, {
    path: "/en/user/overview",
    component: _5246a9ea,
    name: "user-dashboard___en"
  }, {
    path: "/en/user/settings",
    component: _6a20357c,
    name: "user-settings___en"
  }, {
    path: "/en/user/shopping-lists",
    component: _54a269f2,
    name: "user-shoppingLists___en"
  }, {
    path: "/en/checkout/payment/callback",
    component: _d5487828,
    name: "checkout-payment-callback___en"
  }, {
    path: "/",
    component: _45591032,
    name: "index___cs"
  }, {
    path: "/en/user/orders/:id",
    component: _7055b3ae,
    name: "user-orders-id___en"
  }, {
    path: "/en/user/shopping-lists/:id",
    component: _ea9c39a2,
    name: "user-shoppingLists-id___en"
  }, {
    path: "/en/article/:slug",
    component: _f4294074,
    name: "article-slug___en"
  }, {
    path: "/en/articles/:slug",
    component: _25ca04f8,
    name: "articleList-slug___en"
  }, {
    path: "/en/page/:slug",
    component: _06689245,
    name: "page-slug___en"
  }, {
    path: "/en/product/:slug",
    component: _4e9f7c7f,
    name: "product-slug___en"
  }, {
    path: "/uzivatel/nakupni-seznamy/:id",
    component: _ea9c39a2,
    name: "user-shoppingLists-id___cs"
  }, {
    path: "/uzivatel/objednavky/:id",
    component: _7055b3ae,
    name: "user-orders-id___cs"
  }, {
    path: "/clanek/:slug",
    component: _f4294074,
    name: "article-slug___cs"
  }, {
    path: "/clanky/:slug",
    component: _25ca04f8,
    name: "articleList-slug___cs"
  }, {
    path: "/produkt/:slug",
    component: _4e9f7c7f,
    name: "product-slug___cs"
  }, {
    path: "/stranka/:slug",
    component: _06689245,
    name: "page-slug___cs"
  }, {
    path: "/en/*",
    component: _489ff118,
    name: "all___en"
  }, {
    path: "/*",
    component: _489ff118,
    name: "all___cs"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
