import { merge } from 'lodash'
import contentSettings from '~/sites/shop/content.settings'

const overloadSettings = {
  functions: {
    productWildcard: {
      enable: true,
      link: 'all',
    },
  },
}

export default merge(contentSettings, overloadSettings)
