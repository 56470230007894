import axios from 'axios'

export const actions = {
  async GET_RECOMMENDATION({ state, rootState }, { placement, context = {} }) {
    if (!this.$contentSettings.events.quarticon.enable) {
      return [] // Fix recommendation if none is enabled on project
    }
    try {
      let url = `${this.$env.SAIMON_GATEWAY_URL}/${
        this.$env.SAIMON_PROJECT
      }/recommender/recommender/recommendation?widgetId=${state.placements[this.$env.SAIMON_PROJECT][placement]}`
      const response = await axios.post(url, {
        context: {
          projectId: this.$env.SAIMON_PROJECT,
          userId: getSaimonUserId(),
          cartProducts:
            rootState.cart.cartData && rootState.cart.cartData.items
              ? rootState.cart.cartData.items.map(item => item.product.marketingCode)
              : null,
          displayedProductId: context.product ? context.product.marketingCode : null,
          displayedCategoryId: context.category
            ? context.category.id
            : context.virtualCategory
              ? context.virtualCategory._id
              : null,
          productId: context.product ? context.product.marketingCode : null,
        },
      })

      return response.data.product_ids.map(item => ({
        code: item,
        recommendation: {
          type: 'saimon',
          payload: item,
          placement,
          context,
        },
      }))
    } catch (e) {
      console.error(e)
    }
  },
}

export const state = () => ({
  apiKey: '',
  placements: {
    // sexshop: {
    //   category__forYou: '626932c0700fe0339ccf815d',
    //   addToCart__crossSell: '62693379700fe0339ccf8161',
    //   checkout__cartItems: '62693355700fe0339ccf8160',
    //   checkout__emptyCart: '62693265700fe0339ccf815c',
    //   homepage__forYou: '62693265700fe0339ccf815c',
    //   notFound__forYou: '62693265700fe0339ccf815c',
    //   productDetail__related: '6269332a700fe0339ccf815f',
    //   productDetail__crossSell: '62693314700fe0339ccf815e',
    //   internalError__forYou: '62693265700fe0339ccf815c',
    // },
  },
})

export function getSaimonUserId() {
  const v = document.cookie.match('(^|;) ?' + 'saimon-user-id' + '=([^;]*)(;|$)')
  if (v) {
    return v[2]
  }
}
